const NotFound = () => {
  return (
    <div style={{ backgroundColor: 'black', height: '100vh', width: '100%' }}>
      <figure>
        <div class='sad-mac'></div>
        <figcaption>
          <span class='sr-text'>Invalid Payment</span>
        </figcaption>
      </figure>
    </div>
  )
}
export default NotFound
